import * as Icons from "../../page/icons";
import * as React from "react";

import { FormSpec, IconColoring, localized } from "../../../form-specs";
import { LocalizedMenuGroup, WidgetOptions } from "./group.types";
import { MenuWidgetSpec, Widget, isPageWidget, reportWidgetRenderError } from "../../";
import { Site, WithId } from "@maxxton/cms-api";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../i18n";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Group } from "./Group";
import { Identity } from "../../../containers/Identity";
import { PageLinkContext } from "../page-link/pageLink.util";
import { WidgetGroup } from "../../widget.enum";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { linkingSpec } from "../../../inputSpecs/linkingSpec";
import { linkingSpecDefaultValues } from "../../../utils/linking.util";
import namespaceList from "../../../i18n/namespaceList";

const iconList = Object.keys(Icons.default).map((key: any) => ({ label: (Icons.default as any)[key], value: key }));

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "group-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetGroup, "groupWidget"),
    pluralName: getI18nLocaleObject(namespaceList.widgetGroup, "groupWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetGroup, "general"),
                    properties: [
                        [
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "label",
                                        label: getI18nLocaleObject(namespaceList.widgetGroup, "label"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.widgetGroup, "useAsBreadcrumb"),
                                variable: "useAsBreadcrumb",
                                type: "checkbox",
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.widgetGroup, "breadcrumbInformation"),
                                visible: (options: WidgetOptions) => options.useAsBreadcrumb,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "menuItemVisibility"),
                                variable: "hidden",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.useAsBreadcrumb,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetGroup, "multiColumns"),
                                variable: "multiColumns",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.useAsBreadcrumb,
                            },
                            {
                                variable: "showIcons",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconTitle"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.useAsBreadcrumb,
                            },
                            {
                                variable: "iconProperties",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconProperty"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.showIcons,
                                optionList: iconList,
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconColor"),
                                type: "dual-color",
                                default: "color-brand-alt" as IconColoring,
                                visible: (options: WidgetOptions) => options.showIcons,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    properties: [[linkingSpec<WidgetOptions, keyof WidgetOptions>({ variable: "linking" })]],
                    visible: (options: WidgetOptions) => options.useAsBreadcrumb,
                },
            ],
        },
    ],
};

export const groupWidget: MenuWidgetSpec<WidgetOptions> = {
    id: "group",
    type: "menu",
    widgetGroup: WidgetGroup.OTHER,
    name: getI18nLocaleObject(namespaceList.widgetGroup, "groupWidgetOptions"),
    description: getI18nLocaleObject(namespaceList.widgetGroup, "groupWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        localized: [],
        pageLinkId: "",
        siteId: "",
        useAsBreadcrumb: false,
        multiColumns: false,
        showIcons: false,
        iconProperties: "",
        iconColor: "color-brand",
        iconPosition: "top",
        hidden: false,
        linking: linkingSpecDefaultValues,
    }),

    async instanceDescription({ widget, context }): Promise<string> {
        const { localized } = widget.options;
        const { currentLocale, site: contextSite } = context;
        const localizedGroupName: LocalizedMenuGroup | null = getLocalizedContent({ site: contextSite, currentLocale, localizedContent: localized });
        const label = localizedGroupName?.label;
        if (!label) {
            return getI18nLocaleString(namespaceList.widgetGroup, "groupWidgetDescription");
        }
        return label;
    },
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties, allSites?: Array<Site & WithId>, sitemapWidgetOptions?) {
        const { currentLocale, site } = context;
        // eslint-disable-next-line max-len
        const children: Array<JSX.Element | null> = await Promise.all(
            widget.children
                .filter((item) => !item.options.hidden)
                .map((child) => {
                    const childSpec = child.spec;
                    if (isPageWidget(childSpec)) {
                        return (
                            childSpec
                                .render(child, context, sitemapWidgetOptions, undefined, undefined)
                                .then((elem) => <Identity key={child._id}>{elem}</Identity>)
                                // silent the failure of the widget and report error in logstash
                                .catch((err) => {
                                    reportWidgetRenderError(widget, err, childSpec, context);
                                    return null;
                                })
                        );
                    }
                    return (
                        (childSpec as MenuWidgetSpec<any>)
                            .render(child, context, allSites, sitemapWidgetOptions)
                            .then((elem) => <Identity key={child._id}>{elem}</Identity>)
                            // silent the failure of the widget and report error in logstash
                            .catch((err) => {
                                reportWidgetRenderError(widget, err, childSpec, context);
                                return null;
                            })
                    );
                })
        );

        const localizedMenuGroup: LocalizedMenuGroup | null = getLocalizedContent({ site, currentLocale, localizedContent: widget.options.localized });
        const label = localizedMenuGroup?.label || "";
        return (
            <PageLinkContext.Consumer>
                {(pageLinkContext) => {
                    pageLinkContext.isInsideGroup = true;
                    return <Group key={widget._id} label={label} children={children} options={widget.options} />;
                }}
            </PageLinkContext.Consumer>
        );
    },
};
