import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { WidgetOptions } from "./group.types";
import { wrapProps } from "../../../i18n";

interface GroupPropsBase {
    children: Array<JSX.Element | null>;
    label: string;
    options: WidgetOptions;
}

type GroupProps = GroupPropsBase;

interface GroupState {
    dropdownOpen: boolean;
}

export class GroupBase extends React.PureComponent<GroupProps, GroupState> {
    constructor(props: GroupProps) {
        super(props);
        this.state = {
            dropdownOpen: false,
        };
    }

    public render(): JSX.Element | null {
        const { label, children, options } = this.props;
        const { dropdownOpen } = this.state;
        // usage of different icons here
        const iconClassName = options?.iconProperties ? (
            <FontAwesome
                name={options.iconProperties}
                // eslint-disable-next-line max-len
                className={`fontawesome-${options.iconProperties} icon ${
                    options.iconColor && options.iconColor.indexOf("theme") > -1 ? `color-${options.iconColor}` : options.iconColor && options.iconColor.indexOf("rgba") === -1 ? options.iconColor : ""
                }`}
                style={{ color: options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
            />
        ) : undefined;
        const showIcon = options && options.showIcons ? iconClassName : "";
        if (options.multiColumns) {
            return this.renderMultiColumns();
        }

        return (
            // eslint-disable-next-line max-len
            <div className={`${dropdownOpen ? "sub-menu-wrap active" : "sub-menu-wrap"} ${label ? "sub-menu-title" : "no-sub-menu-title"}`}>
                {label && (
                    <label onClick={this.toggle}>
                        {showIcon} {label}
                    </label>
                )}
                <ul>{children}</ul>
            </div>
        );
    }

    private renderMultiColumns() {
        const { label, children } = this.props;
        const { dropdownOpen } = this.state;
        return (
            <div className={`sub-menu-wrap sub-menu-title ${dropdownOpen ? "sub-menu-title active" : "multi-columns"}`}>
                {label && <label onClick={this.toggle}>{label}</label>}
                <ul>{children}</ul>
            </div>
        );
    }

    private toggle = () => {
        this.setState((state) => ({
            ...state,
            dropdownOpen: !state.dropdownOpen,
        }));
    };
}

export const Group = wrapProps<GroupPropsBase>(GroupBase);
